<template>
  <div>
    <div class="outer-container">
      <div class="inner-container">
        <div class="md:border-l md:border-grey-100 md:px-8">
        <div class="max-w-screen-lg m-auto relative">
          <div class="inner-box py-14 md:py-28">
            <div class="text-18 md:text-34 font-bold">We just need a few more details...</div>

            <form action="#" @submit.prevent="submitForm" autocomplete="off">
              <div class="row mt-8 md:mt-12 md:flex md:flex-row -mx-4">
                <div class="col flex-1 px-4">
                  <div class="fields space-y-8">
                    <div class="field">
                      <input
                          id="firstName"
                          type="text"
                          name="firstName"
                          placeholder="First name*"
                          v-model="form.firstName"
                          @input="$v.form.firstName.$touch"
                          class="input-text-black"
                          :class="[{ 'error': $v.form.firstName.$invalid && $v.form.firstName.$dirty }, { 'success': $v.form.firstName.$dirty && !$v.form.firstName.$invalid }]"
                      />
                      <div class="block text-label text-red-600 mt-1" v-if="!$v.form.firstName.required && $v.form.firstName.$dirty">First name is required</div>
                    </div>
                    <div class="field">
                      <input
                          id="lastName"
                          type="text"
                          name="lastName"
                          placeholder="Last name*"
                          v-model="form.lastName"
                          @input="$v.form.lastName.$touch"
                          class="input-text-black"
                          :class="[{ 'error': $v.form.lastName.$invalid && $v.form.lastName.$dirty }, { 'success': $v.form.lastName.$dirty && !$v.form.lastName.$invalid }]"
                      />
                      <div class="block text-label text-red-600 mt-1" v-if="!$v.form.lastName.required && $v.form.lastName.$dirty">Last name is required</div>
                    </div>
                    <div class="field">
                      <input
                          id="email"
                          type="email"
                          name="email"
                          placeholder="Email address*"
                          v-model="form.email"
                          @input="$v.form.email.$touch"
                          class="input-text-black"
                          disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="col flex-1 px-4">
                  <div class="fields space-y-8 mt-8 md:mt-0">
                    <div class="field">
                      <input
                          id="company"
                          type="text"
                          name="company"
                          placeholder="Company name*"
                          v-model="form.company"
                          @input="$v.form.company.$touch"
                          class="input-text-black"
                          :class="[{ 'error': $v.form.company.$invalid && $v.form.company.$dirty }, { 'success': $v.form.company.$dirty && !$v.form.company.$invalid }]"
                      />
                      <div class="block text-label text-red-600 mt-1" v-if="!$v.form.company.required && $v.form.company.$dirty">Company name is required</div>
                    </div>
                    <div class="field">
                      <input
                          id="role"
                          type="text"
                          name="role"
                          placeholder="Occupation / Role*"
                          v-model="form.role"
                          @input="$v.form.role.$touch"
                          class="input-text-black"
                          :class="[{ 'error': $v.form.role.$invalid && $v.form.role.$dirty }, { 'success': $v.form.role.$dirty && !$v.form.role.$invalid }]"
                      />
                      <div class="block text-label text-red-600 mt-1" v-if="!$v.form.role.required && $v.form.role.$dirty">Occupation / Role is required</div>
                    </div>
                    <div class="field">
                      <select
                          id="industry"
                          type="text"
                          name="industry"
                          placeholder="Industry category*"
                          v-model="form.industry"
                          @change="$v.form.industry.$touch"
                          class="input-text-black"
                          :class="[{ 'error': $v.form.industry.$invalid && $v.form.industry.$dirty }, { 'success': $v.form.industry.$dirty && !$v.form.industry.$invalid }]
                      ">
                        <option value="">Industry category*</option>
                        <option :value="item" v-for="(item, index) in industryCategories" :key="'cat' + index">{{item}}</option>
                      </select>
                      <div class="block text-label text-red-600 mt-1" v-if="!$v.form.industry.required && $v.form.industry.$dirty">Industry category is required</div>
                    </div>
                    <div class="field">
                      <input
                          id="website"
                          type="text"
                          name="website"
                          placeholder="Company website"
                          v-model="form.website"
                          class="input-text-black"
                      />
                    </div>
                    <div class="field">
                      <select
                          id="country"
                          type="text"
                          name="country"
                          placeholder="Country*"
                          v-model="form.country"
                          @change="$v.form.country.$touch"
                          class="input-text-black"
                          :class="[{ 'error': $v.form.country.$invalid && $v.form.country.$dirty }, { 'success': $v.form.country.$dirty && !$v.form.country.$invalid }]
                      ">
                        <option value="">Country*</option>
                        <option value="Afganistan">Afghanistan</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bonaire">Bonaire</option>
                        <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                        <option value="Botswana">Botswana</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                        <option value="Brunei">Brunei</option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Canary Islands">Canary Islands</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">Central African Republic</option>
                        <option value="Chad">Chad</option>
                        <option value="Channel Islands">Channel Islands</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">Christmas Island</option>
                        <option value="Cocos Island">Cocos Island</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote DIvoire">Cote DIvoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Curaco">Curacao</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">Dominican Republic</option>
                        <option value="East Timor">East Timor</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands">Falkland Islands</option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">French Polynesia</option>
                        <option value="French Southern Ter">French Southern Ter</option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Great Britain">Great Britain</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="India">India</option>
                        <option value="Iran">Iran</option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea North">Korea North</option>
                        <option value="Korea Sout">Korea South</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Laos">Laos</option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libya">Libya</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macau">Macau</option>
                        <option value="Macedonia">Macedonia</option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">Marshall Islands</option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Midway Islands">Midway Islands</option>
                        <option value="Moldova">Moldova</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Nambia">Nambia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherland Antilles">Netherland Antilles</option>
                        <option value="Netherlands">Netherlands (Holland, Europe)</option>
                        <option value="Nevis">Nevis</option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau Island">Palau Island</option>
                        <option value="Palestine">Palestine</option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">Papua New Guinea</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Phillipines">Philippines</option>
                        <option value="Pitcairn Island">Pitcairn Island</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Republic of Montenegro">Republic of Montenegro</option>
                        <option value="Republic of Serbia">Republic of Serbia</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russia">Russia</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="St Barthelemy">St Barthelemy</option>
                        <option value="St Eustatius">St Eustatius</option>
                        <option value="St Helena">St Helena</option>
                        <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                        <option value="St Lucia">St Lucia</option>
                        <option value="St Maarten">St Maarten</option>
                        <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                        <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                        <option value="Saipan">Saipan</option>
                        <option value="Samoa">Samoa</option>
                        <option value="Samoa American">Samoa American</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syria">Syria</option>
                        <option value="Tahiti">Tahiti</option>
                        <option value="Taiwan">Taiwan</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania">Tanzania</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Erimates">United Arab Emirates</option>
                        <option value="United States of America">United States of America</option>
                        <option value="Uraguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Vatican City State">Vatican City State</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Vietnam">Vietnam</option>
                        <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                        <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                        <option value="Wake Island">Wake Island</option>
                        <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zaire">Zaire</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                      <div class="block text-label text-red-600 mt-1" v-if="!$v.form.country.required && $v.form.country.$dirty">Country is required</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-8 md:mt-12 md:flex md:flex-row -mx-4">
                <div class="col flex-1 px-4 md:pr-2/10">
                  <div class="fields space-y-8">
                    <div class="field">
                      <label class="flex justify-start items-start text-base cursor-pointer">
                        <div class="bg-white border rounded border-gray-300 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4">
                          <input type="checkbox" class="opacity-0 absolute" v-model="form.acceptPersonal" @change="$v.form.acceptPersonal.$touch">
                          <svg class="fill-current hidden w-4 h-4 text-primary-500 pointer-events-none" viewBox="0 0 20 20"><path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/></svg>
                        </div>
                        <div class="select-none">I consent to my personal information being submitted to Wine Australia, who will use it for the purposes of Australian Wine Connect and in accordance with Wine Australia’s <a href="/privacy" target="_blank" class="underline">privacy policy</a>.*</div>
                      </label>
                      <div class="block text-label text-red-600 mt-1" v-if="!$v.form.acceptPersonal.sameAs && $v.form.acceptPersonal.$dirty">Please acknowledge</div>
                    </div>
                    <div class="field">
                      <label class="flex justify-start items-start text-base cursor-pointer">
                        <div class="bg-white border rounded border-gray-300 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4">
                          <input type="checkbox" class="opacity-0 absolute" v-model="form.acceptComms" @change="$v.form.acceptComms.$touch">
                          <svg class="fill-current hidden w-4 h-4 text-primary-500 pointer-events-none" viewBox="0 0 20 20"><path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/></svg>
                        </div>
                        <div class="select-none">I consent to Wine Australia using my personal information to communicate with me regarding Wine Australia’s marketing news, activities and events. I understand I may unsubscribe from these alerts at any time.</div>
                      </label>
                    </div>
                    <div class="field">
                      <label class="flex justify-start items-start text-base cursor-pointer">
                        <div class="bg-white border rounded border-gray-300 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4">
                          <input type="checkbox" class="opacity-0 absolute" v-model="form.acceptInformation" @change="$v.form.acceptInformation.$touch">
                          <svg class="fill-current hidden w-4 h-4 text-primary-500 pointer-events-none" viewBox="0 0 20 20"><path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/></svg>
                        </div>
                        <div class="select-none">I have read and agree to the Australian Wine Connect platform <a href="https://connect.australianwine.com/terms" target="_blank" class="underline">terms and conditions of use</a>.*</div>
                      </label>
                      <div class="block text-label text-red-600 mt-1" v-if="!$v.form.acceptInformation.sameAs && $v.form.acceptInformation.$dirty">Please acknowledge</div>
                    </div>
                    <div class="field">
                      <label class="flex justify-start items-start text-base cursor-pointer">
                        <div class="bg-white border rounded border-gray-300 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-4">
                          <input type="checkbox" class="opacity-0 absolute" v-model="form.acceptAge" @change="$v.form.acceptAge.$touch">
                          <svg class="fill-current hidden w-4 h-4 text-primary-500 pointer-events-none" viewBox="0 0 20 20"><path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/></svg>
                        </div>
                        <div class="select-none">By registering to use the Australian Wine Connect platform, I confirm I am of the national minimum drinking age in my country of residence and I am authorised to purchase alcohol.*</div>
                      </label>
                      <div class="block text-label text-red-600 mt-1" v-if="!$v.form.acceptAge.sameAs && $v.form.acceptAge.$dirty">Please acknowledge</div>
                    </div>
                  </div>
                </div>
                <div class="col px-4 mt-8 md:mt-0 text-right">
                  <button type="submit" class="btn-white btn-wide">Continue</button>
                </div>
              </div>
            </form>

          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import Analytics from '@/analytics'
import Vue from 'vue'
import Vuelidate from 'vuelidate/dist/vuelidate.min'
import { required, sameAs  } from 'vuelidate/dist/validators.min'
Vue.use(Vuelidate)
// import auth0 from 'auth0-js';
import Kontent from '@/kontent'
// import axios from 'axios'
export default {
  name: 'RegisterProfile',
  data () {
    return {
      industryCategories: null,
      form: {
        firstName: this.$auth.user['https://australianwine.com/first_name'] ? this.$auth.user['https://australianwine.com/first_name'] : '',
        lastName: this.$auth.user['https://australianwine.com/last_name'] ? this.$auth.user['https://australianwine.com/last_name'] : '',
        email: this.$auth.user.email ? this.$auth.user.email : '',
        company: this.$auth.user['https://australianwine.com/company_name'] ? this.$auth.user['https://australianwine.com/company_name'] : '',
        role: this.$auth.user['https://australianwine.com/job_role'] ? this.$auth.user['https://australianwine.com/job_role'] : '',
        industry: this.$auth.user['https://australianwine.com/industry_category'] ? this.$auth.user['https://australianwine.com/industry_category'] : '',
        website: this.$auth.user['https://australianwine.com/company_website'] ? this.$auth.user['https://australianwine.com/company_website'] : '',
        country: this.$auth.user['https://australianwine.com/country'] ? this.$auth.user['https://australianwine.com/country'] : '',
        acceptPersonal: this.$auth.user['https://australianwine.com/accept_personal'] ? this.$auth.user['https://australianwine.com/accept_personal'] : '',
        acceptComms: this.$auth.user['https://australianwine.com/accept_comms'] ? this.$auth.user['https://australianwine.com/accept_comms'] : '',
        acceptInformation: this.$auth.user['https://australianwine.com/accept_information'] ? this.$auth.user['https://australianwine.com/accept_information'] : '',
        acceptAge: this.$auth.user['https://australianwine.com/accept_age'] ? this.$auth.user['https://australianwine.com/accept_age'] : '',
      }
    }
  },
  validations: {
    form: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        required
      },
      company: {
        required
      },
      role: {
        required
      },
      industry: {
        required
      },
      country: {
        required
      },
      acceptPersonal: {
        sameAs: sameAs( () => true )
      },
      acceptComms: {
        
      },
      acceptInformation: {
        sameAs: sameAs( () => true )
      },
      acceptAge: {
        sameAs: sameAs( () => true )
      }
    }
  },
  methods: {
    validateForm () {
      this.$v.$touch()
      return !this.$v.$invalid
    },
    submitForm (e) {
      if (this.validateForm(e)) {

        api.updateProfile({
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          companyName: this.form.company,
          jobRole: this.form.role,
          industryCategory: this.form.industry,
          companyWebsite: this.form.website,
          country: this.form.country,
          acceptPersonal: this.form.acceptPersonal,
          acceptComms: this.form.acceptComms,
          acceptInformation: this.form.acceptInformation,
          acceptAge: this.form.acceptAge,
          authState: null,
          signupSource: this.$cookies.get('signup-source') ? this.$cookies.get('signup-source') : null
        }).then(() => {
          // The authState represents an in-progress login state that needs to be continued
          // after the profile is updated. It's either on the user profile (when registration is
          // initiated from a 3rd party like Bigmarker), or in the query string (when an incomplete
          // user attempts to log in to a 3rd party like Bigmarker).
          let authState = this.$route.query.state || this.$auth.user['https://australianwine.com/auth_state'];

          // Continue the login process with the authState, if present.
          // Only if there is a "supportSignUp" or "session_token" query string
          if((window.location.href.indexOf('supportSignUp') > 0 || window.location.href.indexOf('session_token') > 0) && authState)
          {
            window.location.href = `https://${process.env.VUE_APP_AUTH_DOMAIN}/continue?state=${authState}`;
            return;
          }

          let r = '/home'
          if (this.$cookies.get('connect-redirect')) {
            r = this.$cookies.get('connect-redirect')
            this.$cookies.remove('connect-redirect');
          }
          window.location.href = r
        })



        // this.$auth.getTokenSilently().then((token) => {
        //   const auth0Manage = new auth0.Management({
        //     domain: process.env.VUE_APP_AUTH_DOMAIN,
        //     token
        //   });
        //
        //   // The authState represents an in-progress login state that needs to be continued
        //   // after the profile is updated. It's either on the user profile (when registration is
        //   // initiated from a 3rd party like Bigmarker), or in the query string (when an incomplete
        //   // user attempts to log in to a 3rd party like Bigmarker).
        //   let authState = this.$route.query.state || this.$auth.user['https://australianwine.com/auth_state'];
        //
        //   auth0Manage.patchUserMetadata(this.$auth.user.sub, {
        //     first_name: this.form.firstName,
        //     last_name: this.form.lastName,
        //     company_name: this.form.company,
        //     job_role: this.form.role,
        //     industry_category: this.form.industry,
        //     company_website: this.form.website,
        //     country: this.form.country,
        //     accept_personal: this.form.acceptPersonal,
        //     accept_comms: this.form.acceptComms,
        //     accept_information: this.form.acceptInformation,
        //     accept_age: this.form.acceptAge,
        //     auth_state: null
        //   }, () => {
        //
        //     // Continue the login process with the authState, if present.
        //     if(authState)
        //     {
        //       window.location.href = `https://${process.env.VUE_APP_AUTH_DOMAIN}/continue?state=${authState}`;
        //       return;
        //     }
        //
        //     let r = '/home'
        //     if (this.$cookies.get('connect-redirect')) {
        //       r = this.$cookies.get('connect-redirect')
        //       this.$cookies.remove('connect-redirect');
        //     }
        //     window.location.href = r
        //   })
        // })
      }
    }
  },
  mounted () {
    Analytics.trackPage('Register Profile')

    Kontent.getIndustryTypes().then((res) => {
      let cats = []
      for (let i = 0; i < res.data.terms.length; i++) {
        if (res.data.terms[i].name === 'Australian Wine Sector') {
          cats.push('Connect Exhibitor/Australian Winery')
        } else {
          cats.push(res.data.terms[i].name)
        }
      }
      this.industryCategories = cats
    })
  }
}
</script>

<style lang="scss" scoped>

</style>
